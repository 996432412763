@import "~antd/lib/style/index";

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: auto;
}
.text-white {
    color: #23ad46 !important;
    font-size: 5vw;
    font-weight: bold;
}
.text-white-mobile {
    color: #23ad46 !important;
    font-size: 3vw;
    font-weight: bold;
}
.footer1 {
    margin-top: 1rem;
    color: white;
    font-size: 1.9vw;
}
.footer1-mobile {
    margin-top: 1rem;
    color: white;
    font-size: 6vw;
}
.img1 {
    background-repeat: no-repeat;
    padding-bottom: 1.5rem;
}
.img2 {
    background-repeat: no-repeat;
    padding-bottom: 1.5rem;
}
.img3 {
    background-repeat: no-repeat;
    padding-bottom: 1.5rem;
}
.img4 {
    padding-top: 1.25rem;
    background-repeat: no-repeat;
    padding-bottom: 1.75rem;
}
.ant-row-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
}
.feature-properin {
    padding-top: 3rem;
    padding-bottom: 3rem;
}
.feature-properin-mobile {
    padding: 2rem 1.5rem;
}
.section1 {
    background-image: url("images/bg1-cepat.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 6rem;
    padding-bottom: 6rem;
}
.section1-mobile {
    background-image: url("images/bg1-cepat.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
}
.section2 {
    padding-top: 5.5rem;
    padding-left: 3.5rem;
    padding-right: 3.5rem;
    padding-bottom: 5.5rem;
}
.section2-mobile {
    padding: 2rem 3rem 0.5rem 3rem;
}
.section3 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
}
.sectionbutton {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
    padding-top: 10.3rem;
}
.sectionbuttonproject {
    padding-bottom: 3.5rem;
}
.section3-mobile {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0.5rem;
}
.project-mobile {
    padding: 2rem 2rem 3rem 2rem;
}
.section9 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: #27362b;
}
.section9-mobile {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: #27362b;
}
.menuBar {
    padding: 0 20px;
    overflow: auto;
    box-shadow: 0 0 30px #f3f1f1;
}
.ant-card-body {
    padding: 0px;
}
.ant-modal-body {
    padding: 2rem 2rem 4rem 2rem;
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
}
.logo2 {
    float: left;
    padding-top: 3.5rem;
}
.logo2-mobile {
    float: left;
    padding-top: 2.5rem;
}
.logo {
    width: 150px;
    float: left;
}
.logo a {
    display: inline-block;
    font-size: 20px;
    padding: 19px 20px;
    text-transform: capitalize;
}
.textinfo {
    float: left;
    padding-right: 0.5rem;
}
.textinfo-mobile {
    float: left;
    padding-right: 0.6rem;
    width: 9% !important;
}
.textinfodesc {
    padding-right: 1.2rem;
    float: left;
    font-size: 0.9vw;
}
.textinfodesc-mobile {
    padding-right: 1.2rem;
    float: left;
    font-size: 2.5vw;
}
.row-sect1 {
    background-color: #23ad46;
    padding-top: 7rem;
    padding-bottom: 7rem;
    padding-right: 10rem;
    padding-left: 10rem;
}
.row-sect1-mobile {
    background-color: #23ad46;
    padding: 3rem 2rem;
}
.prop-about {
    font-size: 1.9vw;
    color: white;
    padding-top: 1.7rem;
    font-weight: bold;
}
.prop-about-mobile {
    font-size: 5vw;
    color: white;
    font-weight: bold;
}
.text-card-section1 {
    font-size: 1.9vw;
    font-weight: bold;
    color: #23ad46;
}
.title-invest {
    font-weight: bold;
    font-size: 1.4vw;
    padding: 1rem 1rem 0rem 1rem;
    text-align: left;
}
.title-invest-mobile {
    font-weight: bold;
    font-size: 5vw;
    padding: 1rem 2rem 0rem 2rem;
    text-align: left;
}
.textinfoprogress {
    clear: both;
}
.padding-invest {
    padding: 1rem;
    text-align: left;
}
.padding-invest-mobile {
    padding: 1rem 2rem;
    text-align: left;
}
.ant-divider-horizontal {
    display: block;
    clear: both;
    width: 100%;
    min-width: 100%;
    height: 1px;
    margin: 10px 0;
}
.menuCon {
    width: calc(100% - 150px);
    float: left;
}
.menuCon .ant-menu-item {
    padding: 0px 5px;
}
.menuCon .ant-menu-submenu-title {
    padding: 10px 20px;
}
.menuCon .ant-menu-item a,
.menuCon .ant-menu-submenu-title a {
    padding: 10px 15px;
}
.menuCon .ant-menu-horizontal {
    border-bottom: none;
}
.menuCon .leftMenu {
    float: left;
}
.menuCon .rightMenu {
    float: right;
}
.ant-drawer-body {
    padding: 0;
    background: #23ad46;
}
.container-icon {
    padding-top: 31rem;
    padding-bottom: 1rem;
}
.icon-sosmed {
    font-size: 5vw;
    color: white;
    padding: 1.5rem;
}
.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: #23ad46;
}
.barsMenu {
    float: right;
    height: 32px;
    padding: 6px;
    margin-top: 0.8rem;
    display: none;
    background: #23ad46;
}
.barsBtn {
    display: block;
    width: 20px;
    height: 2px;
    top: -6px;
    background: white;
    position: relative;
}
.barsBtn:after,
.barsBtn:before {
    content: attr(x);
    width: 20px;
    position: absolute;
    top: -6px;
    left: 0;
    height: 2px;
    background: white;
}
.barsBtn:after {
    top: auto;
    bottom: -6px;
}
.ant-drawer-body .ant-menu-horizontal > .ant-menu-item,
.ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu {
    display: inline-block;
    width: 100%;
}
.ant-drawer-body .ant-menu-horizontal {
    border-bottom: none;
}
.ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover {
    border-bottom-color: transparent;
}
@media (max-width: 767px) {
    .barsMenu {
        display: inline-block;
    }
    .leftMenu,
    .rightMenu {
        display: none;
    }
    .logo a {
        margin-left: -20px;
    }
    .menuCon .ant-menu-item,
    .menuCon .ant-menu-submenu-title {
        padding: 1px 20px;
    }
    .logo a {
        padding: 10px 20px;
    }
    .topinfo {
        font-size: 20px;
    }
}
.section4 {
    height: 60vh;
    padding-left: 3.5rem;
    padding-right: 3.5rem;
    padding-top: 5.5rem;
    margin-bottom: 9rem;
  }